
import card from './assets/images/labribythesea-card.jpg'
import './styles.scss';

function App() {

  return (
    <div className="main">
      <img src={card} alt="labri by the sea postcard" />
      <a href="https://www.airbnb.ca/rooms/614248863931474533?source_impression_id=p3_1676238668_cVI4b7GQf0E8jon0" tabIndex={0} aria-label="Book Labri by the Sea with Air BnB">Book now with Air BnB</a>
    </div>
  );
}

export default App;
